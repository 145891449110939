iframe {
  display: none;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

.rendercellclass {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}
