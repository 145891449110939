.panel-container .panel-container--no-title {
    height: 134vh !important;
    margin-top: -124px !important;
    z-index: -2 !important;
    background: transparent !important;
    border: none !important;
    overflow: hidden !important;
}

.thumb-vertical {
    display: none !important;
}